.gradient-container {
  width: 100%;
  position: relative;
  height: 2px;
  overflow: hidden;
  .gradient {
    position: absolute;
    transition: left 0.4s ease-out;
    will-change: transform, left;
    //background: green;
    height: 2px;
    width: 15000px;
    background-image: linear-gradient(90deg, #5b1c8b, #88ff4e, #5b1c8b, #5b1c8b, #5b1c8b);
    background-size: 100vw 10px;
    bottom: 0;
    left: 0;
    @media(max-width: 800px) {
      background-size: 1200px 10px;
    }
  }
}