#navbar {
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media (max-width: 1468px) {
    }
    @media (max-width: 780px) {
      gap: 0;
    }
    .navbar-image {
      height: 40px;
      width: 40px;
      min-width: 40px;
    }
  }
}
