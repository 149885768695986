//.sheet {
//  transform: rotateX(55deg) rotateZ(43deg);
//  transform-style: preserve-3d;
//  border-radius: 32px;
//  box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
//  28px 28px 28px 0 rgba(34, 33, 81, 0.25);
//  transition: 0.2s ease-in-out transform, 0.4s ease-in-out box-shadow;
//
//}
//
//.sheet-addedclass {
//  transform: translate3d(0px, -16px, 0px) rotateX(51deg) rotateZ(43deg);
//  box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
//  54px 54px 28px -10px rgba(34, 33, 81, 0.15);
//}

.block-container {
  position: relative;
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .block {
    cursor: pointer;
    height: 200px;
    width: 70%;
    transition: animation 2s ease-in-out, border 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    will-change: animation, transform;
    //animation: slideIn 2s ease-in-out;
    margin-bottom: 25px;
    border: 5px solid white;
    background: white;
    &:hover {
      border-right: 10px solid white;
      border-bottom: 10px solid white;
      //border-radius: 0 15px 0 15px;
      .block-text {
        //border-radius: 0 15px 0 15px;
      }
    }
    .block-text {
      @media(max-width: 800px) {
        padding: 20px;
      }
      transition: animation 2s ease-in-out, border 0.2s ease-in-out, border-radius 0.2s ease-in-out;
      background: #111827;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      .labels {
        margin-left: 15%;
        @media(max-width: 800px) {
          display: none;
        }
      }
    }
  }
}

.fadein-container {
  transform: translate(5vw, -225px);
  opacity: 0;
  animation:  fadeIn 1.6s 1.8s forwards;
}

.slideup-container {
  animation: slideUp 1s 0.5s forwards;
}

.fadeout-container {
  animation: fadeOut 0.8s forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-225px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(5vw, -225px);
  }
  100% {
    opacity: 1;
    transform: translate(0, -225px);
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-5vw);
  }
}