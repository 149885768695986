$scatter-size: 3000px;
$moon-animation-speed: 20s;
.scatter-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
  .relative-container {
    position: relative;
    width: 100%;
    height: 100%;

    .moon {
      will-change: transform;
      position: absolute;
      top: 0;
      left: 5%;
      $moon-size: 400px;
      height: $moon-size;
      width: $moon-size;
      border-radius: 50%;
      background-color: #111827;
      box-shadow: 0 0 100px 30px #5d1d8f;
      transition: transform 2s linear ;
      //background-image: url("../../assets/images/zeniq.png");
      //background-size: cover;
    }

    .constellation {
      will-change: transform;
      position: absolute;
      top: 0;
      left: 5%;
      $moon-size: 400px;
      height: $moon-size;
      width: $moon-size;
      border-radius: 50%;
      background-color: #111827;
      box-shadow: 0 0 100px 30px #5d1d8f;
      transition: transform 2s linear ;
      //background-image: url("../../assets/images/zeniq.png");
      //background-size: cover;
    }

    .wishing-star {
      opacity: 0;
      --rotation: 20deg;
      position: absolute;
      top: 20%;
      height: 3px;
      width: 70px;
      transform:  rotate(var(--rotation));
      background: #7e22ce;
      box-shadow: 0 0 40px 10px #7e22ce;
      animation: wishing-star 10s ease-in-out infinite;
      &.one {
        --rotation: 10deg;
        top: 10%;
        left: 30%;
        animation-delay: 1s;
      }
      &.two {
        --rotation: 20deg;
        top: 10%;
        right: 30%;
        animation-delay: 4s;
      }
      &.three {
        --rotation: -200deg;
        top: 10%;
        left: 10%;
        animation-delay: 8s;
      }
      &.four {
        --rotation: -10deg;
        top: 40%;
        left: 30%;
        animation-delay: 3s;
      }
      &.five {
        --rotation: -190deg;
        top: 10%;
        left: 80%;
        animation-delay: 7s;
      }
    }
  }
}

@keyframes wishing-star {
  0% {
    opacity: 1;
  }
  5% {
    //transform: rotate(20deg) translateX(500px);
    transform: rotate(var(--rotation)) translateX(500px);
    opacity: 0;
  }
  100% {
    //transform: rotate(20deg) translateX(500px);
    transform: rotate(var(--rotation)) translateX(500px);
    opacity: 0;
  }
}