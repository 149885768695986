.gradient-border-container {
  height: 100%;
  width: 100%;
  padding: 5px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  z-index: 3;
  .gradient-border-absolute-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .gradient-border {
      aspect-ratio: 1 / 1;
      transition: all 0.4s ease-out;
      width: 100vw;
      height: 100vw;
      will-change: transform, left, background;
      background: conic-gradient(#5a1c89, #7020b3, #7020b3, #e9963c, #e9963c, #5a1c89);
      background:conic-gradient(from 90deg at 50% 50%, #5a1c89 40%, #e9963c 60%, #5a1c89 80%);
      z-index: 1;
      @media(max-width: 1000px) {
        width: 1500px;
        height: 1500px;
      }
    }
  }
  .gradient-children-container {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 12px;
    z-index: 3;
    background: #111827;
    * {
      z-index: 2;
    }
  }
}