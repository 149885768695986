.wave-container {
  position: relative;
  height: 400px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .waves {
    z-index: 4;
    will-change: transform, left;
    height: 600px;
    position: absolute;
    transition: all 0.6s ease-out;
    transform-origin:  bottom;
    width: 8000px;
    bottom: 0;
    background-image: url("../../assets/images/waves/waves.svg");
    object-fit: cover;
    //background-repeat: repeat-x;
    background-size: cover;
    background-position-y: bottom;
    @media(min-width: 4000px) {
      left: 0 !important;
    }
  }
}

.astronaut {
  height: 1000px;
  @media (max-width: 1200px) {
    height: 700px;
  }
}