@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'hanson';
  src: url('/assets/fonts/Hanson-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'heronSans';
  src: url('/assets/fonts/Font Bureau - HeronSans Bold.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'heronSans';
  src: url('/assets/fonts/Font Bureau - HeronSans Light.otf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'heroSansRegular';
  src: url('/assets/fonts/HeronSansRegular.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'heronSans';
  src: url('/assets/fonts/Font Bureau - HeronSans Medium.otf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'heronSans';
  src: url('/assets/fonts/Font Bureau - HeronSans SemiBold.otf') format('truetype');
  font-weight: semibold;
}

/* Herosancond */

@font-face {
  font-family: 'herosanscond';
  src: url('/assets/fonts/Font Bureau - HeronSansCond Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'herosanscond';
  src: url('/assets/fonts/Font Bureau - HeronSansCond Medium.otf') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: 'herosanscond';
  src: url('/assets/fonts/Font Bureau - HeronSansCond Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'herosanscond';
  src: url('/assets/fonts/Font Bureau - HeronSansCond SemiBold.otf') format('opentype');
  font-weight: semibold;
}


/* DEVASTATINGLY EPIC SCROLLBAR */
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #86fd4f;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b6ff93;
}
::-webkit-scrollbar-thumb:active {
  background-color: #86fd4f;
}
/* DEVASTATINGLY EPIC SCROLLBAR */

video{
  height: 100% !important;
  width: 100% !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

#play-button{
  position: absolute;
  height: 80px;
  cursor: pointer;
}

#__next {
  /*height: 100%;*/
  min-height: 100vh;
}

html,
body {
  height: 100%;
  font-family: "Inter", sans-serif;
  background-color: #010020;
}

.hovered {
  width: 100% !important;
}

@layer components {
  .active {
    @apply border-[1px] border-red-500;
  }
  .route-active {
    @apply transition-all border-b-[4px] border-[#A1E285] text-[#A1E285];
  }
  .route-active-button {
    @apply transition-all bg-[#f4a12f];
  }
  .normal_input {
    @apply px-3 py-3 rounded-md border-[1px] border-black text-black outline-none placeholder-black text-sm;
  }
  p {
    @apply font-normal text-[#E7EEFA];
  }
  .op1 {
    @apply bg-[#00B3A9];
  }
  .op2 {
    @apply bg-[#BD5E80];
  }
  .op3 {
    @apply bg-[#E7A536];
  }
}

li {
  font-family: 'heronSans';
  font-weight: 300;
}

.doc-card-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

#dropdown_select .Dropdown-control {
  background: transparent !important;
  color: #ffffff !important;
  padding: 0.7rem 1rem !important;
}

#dropdown_select .Dropdown-arrow {
  top: 1.1rem !important;
}

#dropdown_select .Dropdown-placeholder {
  color: rgba(128, 128, 128, 0.705) !important;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
}

.home__outer {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #010020;
  isolation: isolate;
  background-position: top 80px center !important;
  color: #fff;
  background-repeat: no-repeat;
}

.css-13cymwt-control,
.css-10sclu4-control {
  background-color: #010222 !important;
  border-color: #324463 !important;
  border-width: 2px !important;
  border-radius: 0 !important;
  color: #A1A1A1 !important;
  outline: none !important;
}
/* Upload project select */
.css-1dimb5e-singleValue {
  color: #A1A1A1 !important;
}

#react-select-2-listbox {
  background-color: #010222 !important;
  color: #A1A1A1 !important;
  border-radius: 0 !important;
  outline: none !important;
}

.css-5hifsb-control:hover,
.css-t3ipsp-control:hover {
  border: 0 !important;
  border-radius: 0 !important;
}